import { useState } from "react";
import { twMerge } from "tailwind-merge";

const View = ({
  data,
  direction = "horizontal",
  target = "",
}: {
  data: any[];
  direction?: "horizontal" | "vertical";
  target?: string;
}) => {
  const [active, setActive] = useState(target);

  return (
    <div>
      <ul
        className={twMerge(
          "flex gap-1",
          direction === "horizontal" ? "" : "flex-col"
        )}
      >
        {data.map((tab) => (
          <li
            className={twMerge(
              "border-t border-x p-2 flex items-center gap-2 cursor-pointer text-typera-primary",
              direction === "horizontal" ? "rounded-t-2xl" : "border-b",
              active === tab.key ? "" : "bg-gray-primary"
            )}
            onClick={() => {
              tab.onAction();
              setActive(tab.key);
            }}
          >
            {tab?.icon}
            <p>{tab.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default View;
