import { useState } from "react";

interface IProps {
  id: string;
  Icon: React.ElementType;
  onAction: (e?: any) => void;
  status?: "enable" | "disable";
  loading?: boolean;
  tooltip?: string;
  canActive?: boolean;
  disable?: boolean;
  className?: string;
  canChangeColor?: boolean;
}

const View = ({
  id,
  Icon,
  onAction,
  status = "enable",
  loading = false,
  tooltip,
  canActive = false,
  disable,
  className,
  canChangeColor = true,
}: IProps) => {
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  return (
    <button
      disabled={disable}
      id={`action-${id}`}
      className={`${
        canChangeColor ? "hover:text-active" : ""
      } relative group grid place-items-center ${className} ${
        active ? "text-active" : "text-icon-default"
      } ${disable ? "cursor-not-allowed" : ""}`}
      onClick={
        // !loading
        !disable
          ? () => {
              onAction();
              if (canActive && !active) {
                setActive(true);
              }
            }
          : () => {}
      }
      onMouseEnter={!disable ? () => setHover(true) : () => {}}
      onMouseLeave={!disable ? () => setHover(false) : () => {}}
    >
      <Icon />
      {hover && tooltip && (
        <span className="absolute font-normal block text-xs p-2 -top-10 bg-active text-white whitespace-nowrap border rounded-md border-none after:absolute after:w-0 after:border-l-8 after:border-r-8 after:border-t-8 after:border-active after:border-b-0 after:border-x-transparent after:-bottom-2 after:block after:left-1/2 after:-translate-x-1/2">
          {tooltip}
        </span>
      )}
    </button>
  );
};

export default View;
