import { IProjectResponse } from "../types/responses";
import { request, parseErrorResponse } from "./request";

const getAll = (): Promise<IProjectResponse[]> =>
  new Promise((resolve, reject) => {
    request()
      .get("projects")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getById = (req: { params: { id: string } }): Promise<IProjectResponse> =>
  new Promise((resolve, reject) => {
    request()
      .get(`projects/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ProjectService = {
  getAll,
  getById,
};

export default ProjectService;
