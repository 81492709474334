import { IResultResponse } from "../types/responses";
import { parseErrorResponse, request } from "./request";

const generateResult = (req: {
  params: { id: string };
  body: {
    socketId: string;
    language: string;
    tone: string;
    variables: { [key: string]: string };
  };
}): Promise<IResultResponse[]> =>
  new Promise((resolve, reject) => {
    request()
      .post(`results/generate/by-project/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CoreService = {
  generateResult,
};

export default CoreService;
