import { IDirectory } from "types/responses";
import { RootState } from "../store";

export const getLanguages = (state: RootState) => state.settings.languages;

export const getCategories = (state: RootState) => state.settings.categories;

export const getProjects = (state: RootState) => state.settings.projects;

export const getPrompts = (state: RootState) => state.settings.prompts;

export const getProjectChats = (state: RootState) =>
  state.settings.project_chats;

export const getPromptChats = (state: RootState) => state.settings.prompt_chats;

export const getDirectories = (state: RootState): IDirectory[] =>
  state.settings.directories;

export const getAuth = (state: RootState) => state.settings.auth;

export const getPlans = (state: RootState) => state.settings.plans;

export const getTranscripts = (state: RootState) => state.settings.transcripts;
