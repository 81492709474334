import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { IInitState } from "./result.slice";
import { IResult, IResultResponse } from "../../types/responses";
import CoreService from "../../services/core";
import ResultService from "../../services/results";

export const setLoading = (
  state: IInitState,
  action: PayloadAction<boolean>
) => {
  state.loading = action.payload;
};

export const removed = (state: IInitState, action: PayloadAction<string>) => {
  const recursive = (array: IResult[], parent: string) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === parent) {
        const childs = array[i].childs;
        array.splice(i, 1);
        array.push(...childs);
        break;
      } else {
        recursive(array[i].childs, parent);
      }
    }
  };
  recursive(state.results, action.payload);
};

export const feedback = (
  state: IInitState,
  action: PayloadAction<{ id: string; text: string }>
) => {
  const { id, text } = action.payload;
  const recursive = (array: IResult[], parent: string) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === parent) {
        array[i].feedback = text;
        break;
      } else {
        recursive(array[i].childs, parent);
      }
    }
  };
  recursive(state.results, id);
};

export const setReResultsStream = (
  state: IInitState,
  action: PayloadAction<any>
) => {
  const recursive = (array: IResult[], parent: string) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === parent) {
        const result = array[i].childs.find(
          (result) => result._id === action.payload.result._id
        );
        if (result) {
          result.text = action.payload.result.text;
          result.isDone = action.payload.isDone;
          break;
        } else {
          array[i].childs.push({
            ...action.payload.result,
            childs: [],
            order: array[i].order + 1,
            isDone: action.payload.isDone,
          } as IResult);
          break;
        }
      } else {
        recursive(array[i].childs, parent);
      }
    }
  };
  recursive(state.results, state.target);
};

export const setResultsStream = (
  state: IInitState,
  action: PayloadAction<any>
) => {
  const result = state.results.find(
    (result) => result._id === action.payload.result._id
  );
  if (result) {
    result.text = action.payload.result.text;
    result.isDone = action.payload.isDone;
  } else {
    state.results = [
      ...state.results,
      {
        ...action.payload.result,
        childs: [],
        order: 0,
        isDone: action.payload.isDone,
      },
    ];
  }
};

export const setResults = (
  state: IInitState,
  action: PayloadAction<IResult[]>
) => {
  state.results = action.payload;
};

export const targetResult = (
  state: IInitState,
  action: PayloadAction<string>
) => {
  state.target = action.payload;
};

export const generateResult = createAsyncThunk(
  "result/generate",
  async (
    data: {
      params: { id: string };
      body: {
        language: string;
        output_language: string;
        tone: string;
        variables: { [key: string]: string };
      };
    },
    { rejectWithValue }
  ): Promise<IResult[]> => {
    const socketId = localStorage.getItem("socketId");
    if (socketId) {
      const res: IResultResponse[] = await CoreService.generateResult({
        params: data.params,
        body: { ...data.body, socketId },
      });
      return res.map((result) => ({
        ...result,
        childs: [],
        order: 0,
        isDone: false,
      }));
    } else {
      return [];
    }
  }
);

export const reGenerateResult = createAsyncThunk(
  "result/re-generate",
  async (
    data: { params: { id: string } },
    { rejectWithValue }
  ): Promise<Pick<IResult, "childs">> => {
    const socketId = localStorage.getItem("socketId") as string;
    const res: IResultResponse = await ResultService.reGenerate({
      params: {
        id: data.params.id,
      },
      body: {
        socketId,
      },
    });
    return {
      ...res,
      childs: [],
    };
  }
);
