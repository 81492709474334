import { useEffect, useState } from "react";
import { WarnIcon } from "../../assets/icons";

interface IProps {
  id?: string;
  value?: string;
  type?: "text" | "email" | "password" | "number";
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  validate?: () => void;
  max?: number;
  min?: number;
  onError?: (e?: any) => void;
  disable?: boolean;
}

const View = (
  {
    id,
    value,
    type = "text",
    onChange,
    className,
    placeholder,
    defaultValue,
    validate = () => {},
    max,
    min,
    onError,
    disable = false,
  }: IProps,
  ref: any
) => {
  const [error, setError] = useState({
    show: false,
    message:
      "Short input. Try to provide more details for better copy results.",
  });

  useEffect(() => {
    if (onError) {
      onError(error.show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.show]);

  const errorHandler = (type: string, value: string) => {
    switch (type) {
      case "min":
        if (value && min) {
          if (value.length < min) {
            setError({
              show: true,
              message:
                "Short input. Try to provide more details for better copy results.",
            });
          } else {
            if (error.show) {
              setError({
                show: false,
                message: "",
              });
            }
          }
        }
        break;
      case "max":
        if (value && max) {
          if (value.length > max) {
            setError({
              show: true,
              message:
                "Long input, try to provide details within the allowed length.",
            });
          } else {
            if (error.show) {
              setError({
                show: false,
                message: "",
              });
            }
          }
        }
        break;
      default:
        return;
    }
  };

  return (
    <>
      <input
        maxLength={(max as number) + 1}
        minLength={min}
        id={id}
        value={value}
        type={type}
        onChange={(e) => {
          errorHandler("max", e.target.value);
          onChange(e.target.value);
        }}
        className={`flex flex-1 border py-2.5 px-3.5 focus:outline-none w-full resize-none rounded shadow-sm text-typera-primary text-sm ${className} ${
          error.show && "border-[#FFAA00]"
        }`}
        placeholder={placeholder}
        onBlur={(e) => {
          validate();
          errorHandler("min", e.target.value);
          errorHandler("max", e.target.value);
        }}
        disabled={disable}
      />
      {error.show && (
        <p className="flex gap-2 text-[#FFAA00] mt-1.5">
          <span className="w-4 h-4">
            <WarnIcon />
          </span>
          <span className="text-xs">{error.message}</span>
        </p>
      )}
    </>
  );
};

export default View;
