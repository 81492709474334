import { AiOutlineCloseCircle, AiFillWarning } from "react-icons/ai";
import { BsFillEmojiSmileFill } from "react-icons/bs";
import { MdError } from "react-icons/md";
import type { MessageStatus } from "../../types/types";

interface IProps {
  message: string;
  onClose: (e?: any) => void;
  visible: boolean;
  type?: MessageStatus;
}

const View = ({ visible, message, onClose, type = "success" }: IProps) => {
  setTimeout(() => {
    onClose();
  }, 5000);

  const renderIcon = () => {
    switch (type) {
      case "success":
        return <BsFillEmojiSmileFill className="w-5 h-5 text-green-500" />;
      case "error":
        return <MdError className="w-5 h-5 text-red-500" />;
      case "warn":
        return <AiFillWarning className="w-5 h-5 text-yellow-500" />;
    }
  };

  return (
    <div
      className={`fixed z-100 ${
        visible ? "animate-drop" : "animate-hide"
      } left-1/2 -translate-x-1/2 bg-white shadow-md rounded-md`}
    >
      <div className="p-4 flex items-center gap-2">
        {renderIcon()}
        <p className="pr-10 text-sm">{message}</p>
        <button onClick={onClose} className="absolute top-2.5 right-2.5">
          <AiOutlineCloseCircle className="text-gray-500" />
        </button>
      </div>
    </div>
  );
};

export default View;
