export const article = (word: string): string => {
  const regexs = ["u", "e", "o", "a", "i"];
  let result = "a";
  for (const char of regexs) {
    if (char === word.toLowerCase().slice(0, 1)) {
      result = "an";
      break;
    }
  }
  return result;
};

export const wordInSequence = (sequence: string): number => {
  const words = sequence.trim().split(" ");
  return words.length;
};

export const colorWordAmongSequence = (sequence: string) => {
  const words = sequence.split(" ");
  const html = words.reduce((prev: string, next: string) => {
    let word: any = next.trim();
    if ((next.startsWith("[") && next.endsWith("]")) || next.endsWith("]\n")) {
      word = `<span class="text-[#7E53C7] font-medium">${next}</span>`;
    }
    return prev + " " + word;
  }, "");
  return html;
};

export const formatNumber = (number: number, minimumFractionDigits = 0) =>
  new Intl.NumberFormat([], {
    minimumFractionDigits: minimumFractionDigits,
  }).format(number);
