import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IObject } from "types/types";

export interface IInitState {
  loading: boolean;
  error: boolean;
  welcome: string;
  messages: IObject;
  lock: boolean;
}

const initialState: IInitState = {
  loading: false,
  error: false,
  welcome: "",
  messages: {
    messages: [],
    history: [],
  },
  lock: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWelcomeStream: (state: IInitState, { payload }) => {
      state.welcome += payload;
    },
    resetMessage: (state: IInitState) => {
      state.messages = {
        messages: [],
        history: [],
      };
    },
    addMessage: (state: IInitState, { payload }) => {
      if (payload.message) {
        state.messages.messages.push(payload.message);
      }
      if (payload.history) {
        state.messages.history.push(payload.history);
      }
    },
    setMessageStream: (state: IInitState, { payload }) => {
      const { id, message, type, done } = payload;
      const targetMessage = state.messages.messages.find(
        (message: any) => message.id === id
      );
      if (targetMessage) {
        targetMessage.message += message;
        state.lock = !done;
      } else {
        state.messages.messages.push({
          id,
          message,
          type,
        });
        state.lock = !done;
      }
    },
  },
});

export const { setWelcomeStream, addMessage, resetMessage, setMessageStream } =
  chatSlice.actions;

export const chatSelector = (state: RootState) => state.chat;

export default chatSlice.reducer;
