import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001/api",
  // timeout: 50000,
});

const axiosUploadInstance = axios.create({
  baseURL: process.env.REACT_APP_UPLOAD_URL || "http://localhost:3002/s3",
  timeout: 50000,
});

export const uploadRequest = (options: any = {}) => {
  return axiosUploadInstance;
};

export const request = (options: any = {}) => {
  const AUTH_TOKEN = localStorage.getItem("access_token");
  axiosInstance.defaults.headers["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common["Authorization"] =
    "Bearer " + AUTH_TOKEN;
  if (options.headers) {
    axiosInstance.defaults.headers = {
      ...axiosInstance.defaults.headers,
      ...options.headers,
    };
  }
  if (options.signal) {
    axiosInstance.defaults.signal = options.signal;
  }

  return axiosInstance;
};

export const parseErrorResponse = (err: any) => {
  if (err && err.response) {
    if (err.response.data.code === 401) {
      //   cookies.remove('authorization');
    }
    return err.response.data;
  }
  return new Error("Bad request");
};

export interface IRequestData {
  body?: {
    [key: string]: any;
  };
  params?: {
    [key: string]: any;
  };
  query?: {
    [key: string]: any;
  };
}
