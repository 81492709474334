import { ACTION_TYPE } from "../types/enums";
import { IResultResponse } from "../types/responses";
import { request, parseErrorResponse } from "./request";

const giveFeedback = (req: {
  params: { id: string };
  body: { feedback: string };
}) =>
  new Promise((resolve, reject) => {
    request()
      .put(`results/${req.params.id}/give-feedback`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const rating = (req: { params: { id: string }; body: { rating: number } }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`results/${req.params.id}/rating`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const reGenerate = (req: {
  params: { id: string };
  body: {
    socketId: string;
  };
}): Promise<IResultResponse> =>
  new Promise((resolve, reject) => {
    request()
      .post(`results/re-generate/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const action = (req: {
  params: { id: string };
  body: { key: string; value: ACTION_TYPE };
}) =>
  new Promise((resolve, reject) => {
    request()
      .put(`results/${req.params.id}/action`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ResultService = {
  giveFeedback,
  rating,
  reGenerate,
  action,
};

export default ResultService;
