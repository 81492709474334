import { ICategoryResponse } from "../types/responses";
import { request, parseErrorResponse } from "./request";

const getAll = (): Promise<ICategoryResponse[]> =>
  new Promise((resolve, reject) => {
    request()
      .get("categories")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByModule = (req: {
  params: { module: string };
}): Promise<ICategoryResponse[]> =>
  new Promise((resolve, reject) => {
    request()
      .get("categories/by-module/" + req.params.module)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CategoryService = {
  getByModule,
  getAll,
};

export default CategoryService;
