import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setShow as setShowAction } from "./portal.action";

export interface IInitState {
  show: boolean;
}

const initialState: IInitState = {
  show: false,
};

const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    setShow: setShowAction,
  },
});

export const { setShow } = portalSlice.actions;

export const portalSelector = (state: RootState) => state.portal;
export default portalSlice.reducer;
