import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IResult } from "../../types/responses";
import {
  feedback as feedbackAction,
  targetResult as targetResultAction,
  setResults as setResultsAction,
  setLoading as setLoadingAction,
  removed as removedAction,
  setResultsStream as setResultsStreamAction,
  setReResultsStream as setReResultsStreamAction,
} from "./result.action";

export interface IInitState {
  target: string;
  results: IResult[];
  loading: boolean;
  error: boolean;
}

const initialState: IInitState = {
  target: "",
  results: [],
  loading: false,
  error: false,
};

const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {
    setResults: setResultsAction,
    targetResult: targetResultAction,
    feedback: feedbackAction,
    setLoading: setLoadingAction,
    removed: removedAction,
    setResultsStream: setResultsStreamAction,
    setReResultsStream: setReResultsStreamAction,
  },
});

export const {
  setResults,
  targetResult,
  feedback,
  setLoading,
  removed,
  setResultsStream,
  setReResultsStream,
} = resultSlice.actions;

export const resultSelector = (state: RootState) => state.results;
export default resultSlice.reducer;
