import { configureStore } from "@reduxjs/toolkit";
import ResultReducer from "./results/result.slice";
import PortalReducer from "./portal/portal.slice";
import SidebarReducer from "./sidebar/sidebar.slice";
import SettingsReducer from "./settings/settings.slice";
import chatDirectoriesReducer from "./chat-directories/chat-directories.slice";
import resultSocketMiddleware from "./middleware/resultSocketMiddleware";
import chatDirectoriesSocketMiddleware from "./middleware/chatDirectoriesSocketMiddleware";
import chatSocketMiddleware from "./middleware/chatSocketMiddleware";
import chatReducer from "./chat/chat.slice";

export const store = configureStore({
  reducer: {
    results: ResultReducer,
    portal: PortalReducer,
    sidebar: SidebarReducer,
    settings: SettingsReducer,
    chatDirectoriess: chatDirectoriesReducer,
    chat: chatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      resultSocketMiddleware,
      chatDirectoriesSocketMiddleware,
      chatSocketMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
