import { IObject } from "types/types";
import { ILoginResponse } from "../types/responses";
import { request, parseErrorResponse } from "./request";

const loginByGoogle = (req: { body: IObject }): Promise<ILoginResponse> =>
  new Promise((resolve, reject) => {
    request()
      .post("login-google", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const login = (req: { body: IObject }): Promise<ILoginResponse> =>
  new Promise((resolve, reject) => {
    request()
      .post("login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("register", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
  register,
  loginByGoogle,
};

export default AuthService;
