import { CloseIcon } from "assets/icons";
import Action from "components/action";
import Button from "components/button";
import Input from "components/input";
import Toast from "components/toast";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getAuth } from "redux/settings/settings.selector";
import { updateAuth } from "redux/settings/settings.slice";
import UserService from "services/users";
import { MessageStatus } from "types/types";

const View = ({ onClose }: { onClose: () => void }) => {
  const auth = useAppSelector(getAuth);
  const dispatch = useAppDispatch();

  const [detail, setDetail] = useState({
    _id: "",
    full_name: "",
    email: "",
    new_email: "",
    password: "",
    confirm_password: "",
  });
  const [toggleChangeEmail, setToggleChangeEmail] = useState(false);
  const [message, setMessage] = useState<{
    show: boolean;
    content: string;
    type: MessageStatus | null;
  }>({
    show: false,
    content: "",
    type: null,
  });

  useEffect(() => {
    setDetail(auth);
  }, [auth]);

  const updateUser = async (key: string, value: string) => {
    try {
      if (key === "password") {
        await UserService.updatePassword({
          params: { id: detail._id },
          body: {
            newPassword: value,
          },
        });
      } else {
        await UserService.update({
          params: { id: detail._id },
          body: {
            [key]: value,
          },
        });
        dispatch(updateAuth({ key, value }));
      }
      setMessage({
        show: true,
        content: "Update successful",
        type: "success",
      });
    } catch (error: any) {
      setMessage({
        show: true,
        content: error.errors,
        type: "error",
      });
    }
  };

  const changeDetailHandler = (key: string, value: string) => {
    setDetail((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleCloseMessage = () => {
    setMessage({
      show: false,
      content: "",
      type: null,
    });
  };

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl w-2/3 bg-white">
      <div className="absolute top-4 right-4">
        <Action Icon={CloseIcon} id="close" onAction={onClose} />
      </div>
      <div className="p-5">
        <div>
          <h3 className="text-typera-primary font-bold text-xl">
            Personal Information
          </h3>
          <div className="grid grid-cols-1 gap-10 mt-5">
            <div>
              <label htmlFor="full_name" className="text-typera-primary">
                Full name
              </label>
              <Input
                id="full_name"
                value={detail.full_name}
                onChange={(e) => changeDetailHandler("full_name", e)}
              />
            </div>
          </div>
          <div className="mt-3">
            <Button
              name="Update name"
              className="!ml-auto lg:!w-1/5"
              onClick={() => {
                updateUser("full_name", detail.full_name);
              }}
            />
          </div>
        </div>
        <hr className="my-5" />
        <div>
          <div className="lg:flex lg:justify-between">
            <h3 className="text-typera-primary font-bold text-xl w-full">
              Email Address
            </h3>
            <Button
              name={toggleChangeEmail ? "Hide" : "Change"}
              className={`lg:!w-1/5 ${
                toggleChangeEmail ? "bg-typera-secondary" : "bg-typera-primary"
              }`}
              onClick={() => setToggleChangeEmail((state) => !state)}
            />
          </div>
          {toggleChangeEmail ? (
            <>
              <div className="lg:grid grid-cols-2 gap-10 mt-5">
                <div>
                  <label
                    htmlFor="current_email"
                    className="text-typera-primary"
                  >
                    Current email address
                  </label>
                  <Input
                    id="current_email"
                    value={detail.email}
                    onChange={() => {}}
                    disable={true}
                  />
                </div>
                <div>
                  <label htmlFor="new_email" className="text-typera-primary">
                    New email
                  </label>
                  <Input
                    id="new_email"
                    value={detail.new_email}
                    onChange={(e) => changeDetailHandler("new_email", e)}
                  />
                </div>
              </div>
              <div className="mt-3">
                <Button
                  name="Update email"
                  className="!ml-auto lg:!w-1/5"
                  onClick={() => {
                    updateUser("email", detail.new_email);
                  }}
                />
              </div>
            </>
          ) : (
            <p className="text-typera-secondary">
              Your email address is <span className="font-bold">{detail.email}</span>
            </p>
          )}
        </div>
        <hr className="my-5" />
        <div>
          <h3 className="text-typera-primary font-bold text-xl">Password</h3>
          <div className="lg:grid grid-cols-2 gap-10 mt-5">
            <div>
              <label htmlFor="password" className="text-typera-primary">
                New password
              </label>
              <Input
                id="password"
                value={detail.password}
                type="password"
                onChange={(e) => changeDetailHandler("password", e)}
              />
            </div>
            <div>
              <label htmlFor="confirm_password" className="text-typera-primary">
                Confirm password
              </label>
              <Input
                id="confirm_password"
                value={detail.confirm_password}
                type="password"
                onChange={(e) => changeDetailHandler("confirm_password", e)}
              />
            </div>
          </div>
          <div className="mt-3">
            <Button
              name="Create password"
              className="!ml-auto lg:!w-1/5"
              onClick={() => {
                updateUser("password", detail.password);
              }}
            />
          </div>
        </div>
      </div>
      {message.show &&
        createPortal(
          <Toast
            visible={message.show}
            message={message.content}
            onClose={handleCloseMessage}
            type={message.type || "success"}
          />,
          document.body
        )}
    </div>
  );
};

export default View;
