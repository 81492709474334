import {
  AddIcon,
  CloseIcon,
  FillIcon,
  InfoIcon,
  InterrogationIcon,
  PencilIcon,
  SearchIcon,
  Send2Icon,
  TrashIcon,
} from "assets/icons";
import Action from "components/action";
import Button from "components/button";
import Input from "components/input";
import Modal from "components/modal";
import { ElementType, useRef } from "react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useAppSelector } from "redux/hooks";
import {
  getProjectChats,
  getPromptChats,
} from "redux/settings/settings.selector";
import { colorWordAmongSequence } from "utils/format";

interface IProps {
  onSubmit: (e?: any) => void;
  type: string;
}

interface IItemProps {
  content: string;
  ckey: string;
  onClick: (e?: any) => void;
  isTarget?: boolean;
  Icon?: ElementType;
  className?: string;
  hasAction?: boolean;
}

interface IPromptProps {
  setUsing: Dispatch<SetStateAction<string>>;
  onClose: (e?: any) => void;
}

const Item = ({
  content,
  ckey,
  isTarget,
  Icon,
  className,
  hasAction = false,
  onClick,
}: IItemProps) => {
  return (
    <li
      className={`px-4 rounded-md py-3 text-sm  text-typera-primary relative cursor-pointer flex gap-3 items-center ${
        isTarget ? "bg-gray-primary" : ""
      } ${className || ""}`}
      onClick={() => {
        onClick(ckey);
      }}
    >
      {Icon && <Icon />}
      {content}
      {hasAction && isTarget && (
        <div className="absolute flex right-3 gap-3">
          <Action id="edit" Icon={PencilIcon} onAction={() => {}} />
          <Action id="remove" Icon={TrashIcon} onAction={() => {}} />
        </div>
      )}
    </li>
  );
};

const Prompts = ({ setUsing, onClose }: IPromptProps) => {
  const projects = useAppSelector(getProjectChats);
  const prompts = useAppSelector(getPromptChats);
  const [targetProject, setTargetProject] = useState<any>("");
  const [targetPrompt, setTargetPrompt] = useState<any>({});
  const [promptsOfProject, setPromptsOfProject] = useState([]);
  const [newPrompt, setNewPrompt] = useState({
    show: false,
    name: "",
    content: "",
  });

  const targetProjectHandler = (id: string) => {
    setTargetProject(id);
    setTargetPrompt("");
    setNewPrompt({
      show: false,
      name: "",
      content: "",
    });
  };

  const targetPromptHandler = (id: string) => {
    setTargetPrompt(id);
  };

  useEffect(() => {
    if (targetProject !== "custom") {
      setPromptsOfProject(
        prompts.filter(
          (prompt: any) => prompt.project_chat_id === targetProject
        ) as unknown as never[]
      );
    } else {
      setPromptsOfProject([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetProject]);

  const promptContent = useMemo(() => {
    if (targetPrompt && promptsOfProject) {
      const prompt = promptsOfProject.find(
        (prompt: any) => prompt._id === targetPrompt
      ) as any;
      if (prompt) {
        return (
          <p
            className="font-normal text-sm text-typera-primary leading-6"
            dangerouslySetInnerHTML={{
              __html: colorWordAmongSequence(prompt.content),
            }}
          />
        );
      } else {
        return "";
      }
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetPrompt]);

  const inputNewPromptHandler = (key: string, value: string | boolean) => {
    setNewPrompt((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const MiddleRender = () => {
    if (targetProject === "custom" && !newPrompt.show) {
      return (
        <div>
          <Button
            name="Create Custom Prompt"
            Icon={AddIcon}
            onClick={() => inputNewPromptHandler("show", true)}
            type="fourth"
          />
        </div>
      );
    }
    if (targetProject === "custom" && newPrompt.show) {
      return (
        <div>
          <Input
            value={newPrompt.name}
            onChange={(e) => inputNewPromptHandler("name", e)}
            className={`mt-0 ${newPrompt.name && "border-active"}`}
            placeholder="Prompt Name"
          />
        </div>
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const PromptsRender = () => {
    if (targetProject !== "custom") {
      return (
        <>
          {promptsOfProject.map((prompt: any) => (
            <Item
              content={prompt.name}
              ckey={prompt._id}
              key={prompt._id}
              isTarget={prompt._id === targetPrompt}
              onClick={() => targetPromptHandler(prompt._id)}
              className="font-extramedium"
            />
          ))}
        </>
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const ContentActionRender = useMemo(() => {
    if (!newPrompt.show) {
      return (
        <Button
          name="Use Prompt"
          onClick={() => {
            const prompt = promptContent as any;
            setUsing(prompt.props.dangerouslySetInnerHTML.__html);
            onClose();
          }}
          className="rounded-lg"
          disabling={!newPrompt.show && !promptContent}
        />
      );
    }
    return (
      <>
        <div className="flex gap-2">
          <InfoIcon />
          <p className="font-normal text-xs text-icon-default">
            Your custom prompts will be shared across your workspace
          </p>
        </div>
        <div className="flex gap-2">
          <Button
            name="Cancel"
            onClick={() => inputNewPromptHandler("show", false)}
            className="flex-1.5 bg-transparent border rounded text-typera-primary font-extramedium text-sm border-gray-400"
            type="custom"
          />
          <Button
            name="Save New Prompt"
            onClick={() => {}}
            className="flex-2"
            disabling={newPrompt.name || newPrompt.content ? false : true}
          />
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPrompt.show, newPrompt.name, newPrompt.content, promptContent]);

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl w-2/3 flex flex-col overflow-hidden">
      <div className="min-h-[64px] bg-gray-secondary relative">
        <div className="absolute top-1/2 -translate-y-1/2 right-6">
          <Action Icon={CloseIcon} id="close" onAction={onClose} />
        </div>
      </div>
      <div
        className="flex-1 bg-gray-primary flex "
        style={{
          maxHeight: "calc(-300px + 100vh)",
          minHeight: "calc(-300px + 100vh)",
        }}
      >
        <div className="w-[30%] bg-white border-t border-r p-3">
          <div className="relative mb-3">
            <div className="absolute ml-4 inset-y-0 h-full text-gray-thirt flex items-center">
              <SearchIcon />
            </div>
            <input
              type="text"
              className="py-3 text-sm text-black pl-12 pr-2 rounded shadow-sm resize-none w-full focus:outline-none focus:ring-0 border"
              placeholder={`Try "Ads" or "Marketing"`}
            />
          </div>
          <Item
            content="Custom"
            ckey="custom"
            key="custom"
            isTarget={"custom" === targetProject}
            onClick={() => targetProjectHandler("custom")}
            Icon={FillIcon}
            className="font-extramedium text-base"
          />
          <hr className="my-3" />
          <ul className="flex flex-col gap-y-1 h-full overflow-y-scroll max-h-[78.5%] mb-10">
            {projects.map((project: any) => (
              <Item
                content={project.name}
                ckey={project._id}
                key={project._id}
                isTarget={project._id === targetProject}
                onClick={() => targetProjectHandler(project._id)}
                className="font-extramedium"
              />
            ))}
          </ul>
        </div>
        <div className="w-[30%] bg-white border-t p-3">
          <ul className="flex flex-col gap-y-1 h-full overflow-y-scroll">
            {MiddleRender()}
            {PromptsRender()}
          </ul>
        </div>
        <div className="w-[40%] p-2 flex flex-col gap-3">
          <div className="bg-gray-secondary flex-1 rounded-xl p-3 relative">
            {newPrompt.show && (
              <div
                className="h-full font-normal text-sm text-typera-primary"
                contentEditable
                onBlur={(e) => {
                  e.target.innerHTML = colorWordAmongSequence(
                    newPrompt.content
                  );
                }}
                onInput={(e) => {
                  const target = e.target as HTMLElement;
                  inputNewPromptHandler("content", target.innerText);
                }}
              >
                <div className="font-medium text-sm text-icon-default">
                  <p>Enter your prompt here</p>
                  <p>Tip: Use [brackets] to insert placeholder</p>
                </div>
              </div>
            )}
            {!newPrompt.show && (
              <div className="flex gap-2 items-center">
                <InterrogationIcon />{" "}
                <p className="font-medium text-sm text-icon-default">Preview</p>
              </div>
            )}
            {promptContent && <div className="mt-6">{promptContent}</div>}
          </div>
          {ContentActionRender}
        </div>
      </div>
    </div>
  );
};

const View = ({ onSubmit, type }: IProps) => {
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [message, setMessage] = useState("");
  const [usingPrompt, setUsingPrompt] = useState("");
  const messageRef = useRef<any>(null);

  useEffect(() => {
    if (usingPrompt) {
      if (messageRef.current) {
        messageRef.current.innerHTML = usingPrompt;
        setMessage(usingPrompt);
        setUsingPrompt("");
      }
    }
  }, [usingPrompt]);

  const submitHandler = () => {
    if (message.trim()) {
      const dom = new DOMParser().parseFromString(
        message.trim(),
        "text/html"
      ) as any;
      let mess = dom.children[0].innerText;
      setMessage("");
      onSubmit(mess);
      messageRef.current.innerText = "";
    }
  };

  return (
    <>
      <div className="w-full rounded-lg overflow-hidden">
        <div className="h-14 bg-gray-secondary flex items-center px-4">
          {type === "pdf" ? (
            <span className="flex items-center text-typera-secondary px-3 py-0 h-10 font-medium text-sm rounded-md">
              Note: AI only answers your question based on the data from the
              uploaded file.
            </span>
          ) : type === "shortcast" ? (
            <span className="flex items-center text-typera-secondary px-3 py-0 h-10 font-medium text-sm rounded-md">
              Note: AI only answers your question from Podcast or Audio content.
            </span>
          ) : (
            <button
              onClick={() => setShowPromptModal(true)}
              className="text-[#222B45] px-3 py-0 h-8 bg-[#DAC6F5]/30 font-bold text-sm rounded-md"
            >
              Browse prompts
            </button>
          )}
        </div>
        <div className="relative">
          <div
            ref={messageRef}
            className="bg-[#EDF1F7] outline-none p-5 font-medium text-sm text-typera-primary border-0"
            contentEditable
            data-placeholder="Ask AI for anything!"
            onInput={(e) => {
              const target = e.target as HTMLElement;
              setMessage(target.innerText);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                submitHandler();
              }
            }}
          />
          <div className="absolute z-10 right-2 -bottom-2 -translate-y-1/2">
            <Action
              id={"send-message"}
              key={"send-message"}
              Icon={Send2Icon}
              onAction={submitHandler}
              canActive={false}
              className={`${
                !message ? "bg-[#E4E9F2]" : "bg-active text-white"
              } h-8 w-8 rounded-md`}
              canChangeColor={false}
            />
          </div>
        </div>
      </div>
      {showPromptModal &&
        createPortal(
          <Modal>
            <Prompts
              setUsing={setUsingPrompt}
              onClose={() => setShowPromptModal(false)}
            />
          </Modal>,
          document.body
        )}
    </>
  );
};

export default View;
