import { useState } from "react";
import Button from "../../components/button";
import Input from "../../components/input";
import AuthService from "../../services/auth";
import { useNavigate } from "react-router-dom";

const View = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [registerValue, setRegisterValue] = useState({
    email: "",
    password: "",
    full_name: "",
    phone: "",
  });

  const handleInputLogin = (key: string, value: string) => {
    setRegisterValue((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      const res = await AuthService.register({ body: registerValue });
      setLoading(false);
      if (res) {
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen grid place-items-center relative bg-[#eff3f8]">
      <div
        style={{
          borderRadius: "56px",
          padding: "0.2rem",
          background:
            "linear-gradient(180deg, #170247 10%, rgba(33, 150, 243, 0) 30%)",
        }}
      >
        <div
          className="overflow-hidden bg-white"
          style={{ borderRadius: "53px" }}
        >
          <div className="p-[5rem] flex flex-col gap-5 md:w-[30rem]">
            <div className="text-center">
              <h3 className="font-bold text-3xl text-typera-primary mb-3">
                Welcome!
              </h3>
              <p className="font-semibold text-icon-default text-sm">
                Sign up to continue
              </p>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <Input
                id="email"
                type="email"
                onChange={(e) => handleInputLogin("email", e)}
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="full_name">FullName</label>
              <Input
                id="full_name"
                type="text"
                onChange={(e) => handleInputLogin("full_name", e)}
                placeholder="Your fullname"
              />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <Input
                id="phone"
                type="number"
                onChange={(e) => handleInputLogin("phone", e)}
                placeholder="Your phone"
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <Input
                id="password"
                type="password"
                onChange={(e) => handleInputLogin("password", e)}
                placeholder="Password"
              />
            </div>
            <Button
              loading={loading}
              name="Sign Up"
              type="primary"
              onClick={handleRegister}
            />
            <hr />
            <p className="text-xs text-typera-primary text-center">
              Already have account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="text-green-500 font-extramedium cursor-pointer"
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
