import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setShow as setShowAction } from "./sidebar.action";
import { isMobile } from "react-device-detect";

export interface IInitState {
  show: boolean;
}

const initialState: IInitState = {
  show: isMobile ? false : true,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setShow: setShowAction,
  },
});

export const { setShow } = sidebarSlice.actions;

export const sidebarSelector = (state: RootState) => state.sidebar;
export default sidebarSlice.reducer;
