import { IObject } from "types/types";
import { request, parseErrorResponse } from "./request";
import qs from "querystring";
import { IPlan } from "types/responses";

const checkout = (req: IObject) =>
  new Promise(async (resolve, reject) => {
    request()
      .post("stripe/checkout", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went edrror!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const lineItem = (req: IObject) =>
  new Promise(async (resolve, reject) => {
    const querystring = qs.stringify(req.query);
    request()
      .get(`stripe/line-item?${querystring}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went edrror!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const plans = async (): Promise<IPlan[]> =>
  new Promise(async (resolve, reject) => {
    request()
      .get("/stripe/plans")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const manageSubscription = (req: {
  body: { customerId: string; origin: string };
}): Promise<{ url: string }> =>
  new Promise(async (resolve, reject) => {
    request()
      .post("/stripe/manage-subscription", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const StripeService = {
  checkout,
  lineItem,
  plans,
  manageSubscription,
};

export default StripeService;
