import { useEffect, useRef, useState } from "react";
import { WarnIcon } from "../../assets/icons";

interface IProps {
  ckey: string;
  value: string;
  onChange: (value: string) => void;
  rows?: number;
  className?: string;
  placeholder?: string;
  editable?: boolean;
  max?: number;
  min?: number;
  onError?: (e?: any) => void;
}

const View = ({
  ckey,
  value,
  onChange,
  rows = 1,
  className,
  placeholder,
  editable = true,
  max,
  min,
  onError,
}: IProps) => {
  const cref = useRef<any>(null);
  const [error, setError] = useState({
    show: false,
    message:
      "Short input. Try to provide more details for better copy results.",
  });

  const errorHandler = (type: string, value: string) => {
    switch (type) {
      case "min":
        if (value && min) {
          if (value.length < min) {
            setError({
              show: true,
              message:
                "Short input. Try to provide more details for better copy results.",
            });
          } else {
            if (error.show) {
              setError({
                show: false,
                message: "",
              });
            }
          }
        }
        break;
      case "max":
        if (value && max) {
          if (value.length > max) {
            setError({
              show: true,
              message:
                "Long input, try to provide details within the allowed length.",
            });
          } else {
            if (error.show) {
              setError({
                show: false,
                message: "",
              });
            }
          }
        }
        break;
      default:
        return;
    }
  };

  const resize = () => {
    cref.current.style.height = "auto";
    cref.current.style.height = cref.current.scrollHeight + "px";
  };

  useEffect(resize, [value]);

  useEffect(() => {
    if (onError) {
      onError(error.show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.show]);

  return (
    <>
      <textarea
        maxLength={(max as number) + 1}
        minLength={min}
        value={value}
        ref={cref}
        onChange={(e) => {
          errorHandler("max", e.target.value);
          onChange(e.target.value);
        }}
        rows={rows}
        className={`flex flex-1 border min-h-[50px] font-normal focus:outline-none w-full rounded text-typera-primary ${className} text-sm ${
          !editable
            ? "bg-white border-none resize-none shadow-none p-0"
            : "shadow-sm py-2.5 px-3.5"
        } ${error.show && "border-[#FFAA00]"}`}
        placeholder={placeholder}
        disabled={!editable}
        onBlur={(e) => {
          errorHandler("min", e.target.value);
          errorHandler("max", e.target.value);
        }}
      ></textarea>
      {error.show && (
        <p className="flex gap-2 text-[#FFAA00] mt-1.5">
          <span className="w-4 h-4">
            <WarnIcon />
          </span>
          <span className="text-xs">{error.message}</span>
        </p>
      )}
    </>
  );
};

export default View;
