import { VscLoading } from "react-icons/vsc";

interface IProps {
  sub?: boolean;
  className?: string;
  iStyle?: string;
}

const View = ({ sub = true, className, iStyle }: IProps) => {
  return (
    <div className={`flex items-center gap-2 justify-center ${className}`}>
      <VscLoading className={`animate-spin text-typera-primary ${iStyle}`} />
      {sub && <span className="text-sm text-typera-primary text-extramedium">Processing...</span>}
    </div>
  );
};

export default View;
