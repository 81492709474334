import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

const View = ({ children }: IProps) => {
  return (
    <div className="fixed h-screen w-screen top-0 bg-black-blur z-100">
      {children}
    </div>
  );
};

export default View;
