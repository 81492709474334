import {
  setMessageStream,
  setMessageShortcastStream,
} from "redux/chat-directories/chat-directories.slice";
import io from "socket.io-client";

const chatPDFSocketMiddleware = (store: any) => (next: any) => {
  const token = localStorage.getItem("access_token");
  const socket = io(`${process.env.REACT_APP_SOCKET_URL}/directories-chat`, {
    query: {
      token,
    },
  });

  socket.on("user-socket", (id) => {
    localStorage.setItem("socketIdDirectoriesPDF", id);
  });

  socket.on("chat-pdf", ({ id, type, message, done }) => {
    store.dispatch(setMessageStream({ id, type, message, done }));
  });

  socket.on("chat-shortcast", ({ id, type, message, done }) => {
    store.dispatch(setMessageShortcastStream({ id, type, message, done }));
  });

  return (action: any) => {
    switch (action.type) {
      case "chat/directories": {
        if (action.payload) {
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};

export default chatPDFSocketMiddleware;
