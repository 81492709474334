import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "querystring";

export const isExist = (req: IRequestData) =>
  new Promise(async (resolve, reject) => {
    try {
      const querystring = qs.stringify(req.query);
      request()
        .get(`count/exist?${querystring}`)
        .then((res) => {
          const { data } = res.data;
          resolve(data);
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {
      reject(error);
    }
  });

export const changeSubscription = (req: IRequestData) =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .put("count/change-subscription", {
          ...req.body,
        })
        .then((res) => {
          const { data } = res.data;
          if (data) resolve(data);
          else reject(new Error("Something went error!"));
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {
      reject(error);
    }
  });

export const createCount = (req: IRequestData) =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .post("count", {
          ...req.body,
        })
        .then((res) => {
          const { data } = res.data;
          if (data) resolve(data);
          else reject(new Error("Something went error!"));
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {
      reject(error);
    }
  });
