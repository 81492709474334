import { request, parseErrorResponse } from "./request";

const getByProject = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`prompt-chat/by-project/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get("prompt-chat")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PromptChatService = {
  getByProject,
  getAll,
};

export default PromptChatService;
