import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IObject } from "types/types";

export interface IInitState {
  loading: boolean;
  error: boolean;
  welcome: string;
  messages: IObject;
  lock: boolean;
  messages_shortcast: IObject;
}

const initialState: IInitState = {
  loading: false,
  error: false,
  welcome: "",
  messages: {
    messages: [],
    history: [],
  },
  lock: false,
  messages_shortcast: {
    messages: [],
    history: [],
  },
};

const chatDirectoriesSlice = createSlice({
  name: "chatDirectories",
  initialState,
  reducers: {
    resetMessageShortcast: (state: IInitState) => {
      state.messages_shortcast = {
        messages: [],
        history: [],
      };
    },
    addMessageShortcast: (state: IInitState, { payload }) => {
      if (payload.message) {
        state.messages_shortcast.messages.push(payload.message);
      }
      if (payload.history) {
        state.messages_shortcast.history.push(payload.history);
      }
    },
    setMessageShortcastStream: (state: IInitState, { payload }) => {
      const { id, message, type, done } = payload;
      const targetMessage = state.messages_shortcast.messages.find(
        (message: any) => message.id === id
      );
      if (targetMessage) {
        targetMessage.message += message;
        state.lock = !done;
      } else {
        state.messages_shortcast.messages.push({
          id,
          message,
          type,
          sourceDocuments: [],
        });
        state.lock = !done;
      }
    },
    setWelcomeStream: (state: IInitState, { payload }) => {
      state.welcome += payload;
    },
    resetMessage: (state: IInitState) => {
      state.messages = {
        messages: [],
        history: [],
      };
    },
    addMessage: (state: IInitState, { payload }) => {
      if (payload.message) {
        state.messages.messages.push(payload.message);
      }
      if (payload.history) {
        state.messages.history.push(payload.history);
      }
    },
    setMessageStream: (state: IInitState, { payload }) => {
      const { id, message, type, done } = payload;
      const targetMessage = state.messages.messages.find(
        (message: any) => message.id === id
      );
      if (targetMessage) {
        targetMessage.message += message;
        state.lock = !done;
      } else {
        state.messages.messages.push({
          id,
          message,
          type,
          sourceDocuments: [],
        });
        state.lock = !done;
      }
    },
    addSourceIntoMessage: (state: IInitState, { payload }) => {
      const { messageId, sourceDocuments } = payload;
      state.messages.messages = state.messages.messages.map((item: any) => {
        if (messageId === item.id) {
          return {
            ...item,
            sourceDocuments,
          };
        }
        return item;
      });
    },
  },
});

export const {
  setWelcomeStream,
  addMessage,
  resetMessage,
  setMessageStream,
  addSourceIntoMessage,
  setMessageShortcastStream,
  addMessageShortcast,
  resetMessageShortcast,
} = chatDirectoriesSlice.actions;

export const chatDirectoriesSelector = (state: RootState) =>
  state.chatDirectoriess;

export default chatDirectoriesSlice.reducer;
