interface IProps {
  icon?: string;
  name: string;
  ckey: string;
  onClick: (e?: any) => void;
}

const View = ({ icon, name, onClick, ckey }: IProps) => {
  return (
    <div className="grid cursor-pointer py-1 px-2" onClick={onClick}>
      <div>
        <img
          src={`/images/icons/${icon}`}
          alt="icon"
          className="w-4 inline-block mr-2"
        />
        <p className="text-typera-secondary text-xs inline-block">{name}</p>
      </div>
    </div>
  );
};

export default View;
